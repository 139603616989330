import { useFormatter } from 'next-intl'
import { useCallback } from 'react'

type UsePriceFormatterOptions = {
  shouldHideCurrency?: boolean
}

export function usePriceFormatter(options?: UsePriceFormatterOptions) {
  const format = useFormatter()

  return useCallback(
    (amount?: number, currency?: string) => {
      const formatted = format.number(amount || 0, {
        style: 'currency',
        currency,
        currencyDisplay: 'narrowSymbol',
      })

      if (options?.shouldHideCurrency) {
        return formatted
      }

      return `${formatted} ${currency}`
    },
    [format, options?.shouldHideCurrency]
  )
}
