import { useRecurly } from '@recurly/react-recurly'
import React, { useEffect, useState } from 'react'

import { useAnalytics } from '@/modules/analytics/hooks/use-analytics'

import { type Plan } from '../atoms'
import { useCheckoutMutation } from './use-checkout-mutation'

interface UsePaypal {
  plan?: Plan
  onError?: (errorMessage: string) => void
  successRedirect?: () => void
}

interface PaypalTokenId {
  id: string
  email: string
  payer_id: string
}

type PaypalError = Error & {
  cause: {
    code: string
    message: string
  }
}

const PAYPAL_ERROR_CODES = {
  CANCELED: 'paypal-canceled',
}

type PaypalCompleteConfig = Parameters<ReturnType<typeof useRecurly>['PayPal']>[0] & {
  payPalComplete?: boolean
}

export function usePaypal({ plan, onError, successRedirect }: UsePaypal) {
  const [isPaypalPending, setIsPaypalPending] = React.useState(false)
  const recurly = useRecurly()
  const [paypal, setPaypal] = useState<ReturnType<typeof recurly.PayPal> | null>(null)
  const [paypalTokenId, setPaypalTokenId] = useState<string | null>(null)

  const checkoutMutation = useCheckoutMutation({
    onMutate: () => {
      setIsPaypalPending(false)
    },
    successRedirect,
    onError: (error) => {
      onError?.(error.message)
    },
  })
  const { logEvent } = useAnalytics()

  const checkoutOption = React.useMemo(() => {
    return plan?.checkout_options.find((checkoutOption) => checkoutOption.psp_type === 'recurly')
  }, [plan?.checkout_options])

  const startPaypal = React.useCallback(() => {
    if (!paypal) return
    paypal.start()
    setIsPaypalPending(true)
  }, [paypal])

  useEffect(() => {
    if (!paypal) return

    paypal.on('token', (token: PaypalTokenId) => {
      setPaypalTokenId(token.id)
    })

    paypal.on('cancel', () => {
      setIsPaypalPending(false)
      logEvent('membership_payment_provider_flow_cancel', { payment_provider_type: 'recurly' })
    })

    paypal.on('error', (error: PaypalError) => {
      setIsPaypalPending(false)

      if (error.cause.code === PAYPAL_ERROR_CODES.CANCELED) {
        logEvent('membership_payment_provider_flow_cancel', { payment_provider_type: 'recurly' })

        return
      }

      logEvent('membership_payment_provider_flow_failed', {
        reason: error.name,
        payment_provider_type: 'paypal',
      })

      onError?.(error.message)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paypal])

  useEffect(() => {
    if (!checkoutOption || !plan || !paypalTokenId) {
      return
    }

    checkoutMutation.mutate({
      psp_token: paypalTokenId,
      psp_type: checkoutOption.psp_type,
      psp_product_id: checkoutOption.psp_product_id,
      amount: plan.amount,
      currency: plan.currency,
    })

    setPaypalTokenId(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paypalTokenId, checkoutOption, plan])

  useEffect(() => {
    if (!plan) return

    setPaypal(
      (recurly.PayPal as (config: PaypalCompleteConfig) => ReturnType<typeof recurly.PayPal>)({
        payPalComplete: true,
        display: {
          displayName: plan.productName,
          amount: plan.amount ? `${plan.amount}` : undefined,
        },
      })
    )

    return () => {
      paypal?.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan])

  return {
    checkoutOption,
    isPending: isPaypalPending || checkoutMutation.isPending,
    isSuccess: checkoutMutation.isSuccess,
    startPaypal,
  }
}
