/* eslint-disable @typescript-eslint/no-explicit-any */

export function flatStringValuesFromObject(obj: Record<string, any>): string[] {
  const result: string[] = []

  function recurse(currentObj: Record<string, any>) {
    for (const key in currentObj) {
      if (typeof currentObj[key] === 'object' && currentObj[key] !== null) {
        recurse(currentObj[key])
      } else if (typeof currentObj[key] === 'string') {
        result.push(currentObj[key])
      }
    }
  }

  recurse(obj)

  return result
}
