import { useRecurly } from '@recurly/react-recurly'
import { useCallback, useMemo, useState, type RefObject } from 'react'

import { useAnalytics } from '@/modules/analytics/hooks/use-analytics'
import { isHammerTimeError } from '@/modules/api/hammer-time/lib/errors'

import { type Plan } from '../atoms'
import { useCheckoutMutation } from './use-checkout-mutation'

type UseCreditCardPaymentOptions = {
  successRedirect?: () => void
}

export function useCreditCardPayment(
  formRef: RefObject<HTMLFormElement>,
  plan: Plan,
  options?: UseCreditCardPaymentOptions
) {
  const recurly = useRecurly()
  const { logEvent } = useAnalytics()
  const [recurlyError, setRecurlyError] = useState<string | null>(null)
  const [initToken, setInitToken] = useState('')
  const checkoutOption = useMemo(() => plan.checkout_options.find((option) => option.psp_type === 'recurly'), [plan])
  const checkoutMutation = useCheckoutMutation({
    successRedirect: options?.successRedirect,
  })

  const handleCheckout = useCallback(
    (token: string, three3dsToken: string | null = null) => {
      if (!checkoutOption) {
        throw new Error('checkoutOption is required')
      }

      setRecurlyError(null)

      checkoutMutation.mutate({
        psp_token: token,
        psp_type: checkoutOption.psp_type,
        psp_product_id: checkoutOption.psp_product_id,
        amount: plan.amount,
        currency: plan.currency,
        ...(three3dsToken && { three_d_secure_action_result_token_id: three3dsToken }),
      })
    },
    [checkoutOption, checkoutMutation, plan]
  )

  const submitPayment = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      if (!formRef.current) return

      event.preventDefault()
      recurly.token(formRef.current, (err, token) => {
        if (err) {
          logEvent('membership_payment_provider_flow_failed', {
            reason: err.code,
            payment_provider_type: 'recurly',
          })
          setRecurlyError(err.message)
        } else {
          setInitToken(token.id)
          handleCheckout(token.id)
        }
      })
    },
    [formRef, plan, checkoutOption, checkoutMutation, logEvent, recurly, setRecurlyError, handleCheckout]
  )

  const submit3DS = useCallback(
    (three3dsToken: string) => {
      handleCheckout(initToken, three3dsToken)
    },
    [handleCheckout, initToken]
  )

  return useMemo(() => {
    const errorCode = isHammerTimeError(checkoutMutation.error?.shape?.data)
      ? checkoutMutation.error.shape.data.hammertimeError?.[0]?.code
      : null

    const is3DSRequired = errorCode === '3d_secure_required'
    const mutationErrorMessage = is3DSRequired ? '' : checkoutMutation.error?.message

    return {
      submitPayment,
      setRecurlyError,
      submit3DS,
      token3DS: is3DSRequired && checkoutMutation.error?.message,
      errorMessage: recurlyError || mutationErrorMessage,
      isPending: checkoutMutation.isPending || checkoutMutation.isSuccess,
    }
  }, [
    submitPayment,
    recurlyError,
    checkoutMutation.error,
    checkoutMutation.isPending,
    checkoutMutation.isSuccess,
    submit3DS,
  ])
}
