import { atom } from 'jotai'

import { type Variant } from '@/modules/api/trpc/lib/schemas'

export type Plan = Variant & { productName?: string; isFree?: boolean }

export const selectedPlanAtom = atom<Plan | null>(null)

type CheckoutMutationGlobalStatus = 'error' | 'pending' | 'success' | 'idle'

export const checkoutMutationGlobalStatusAtom = atom<CheckoutMutationGlobalStatus>('idle')
