'use client'

import { Alert, Stack } from '@mui/material'
import { useAtomValue } from 'jotai'
import { useTranslations } from 'next-intl'
import React from 'react'

import { Button } from '@/modules/ui/components/button'
import { PaypalIcon } from '@/modules/ui/icons/paypal'

import classes from './paypal-button.module.scss'
import { checkoutMutationGlobalStatusAtom, type Plan } from '../../atoms'
import { usePaypal } from '../../hooks/use-paypal'

type PaypalProps = {
  readonly plan: Plan
  readonly successRedirect?: () => void
}

export function PaypalButton({ plan, successRedirect }: PaypalProps) {
  const t = useTranslations('common')
  const checkoutMutationGlobalStatus = useAtomValue(checkoutMutationGlobalStatusAtom)
  const [error, setError] = React.useState<string | null>(null)

  const { checkoutOption, isPending, isSuccess, startPaypal } = usePaypal({
    plan,
    onError: (errorMessage) => setError(errorMessage),
    successRedirect,
  })

  if (!checkoutOption) return null

  const isDisabled = checkoutMutationGlobalStatus === 'pending' || checkoutMutationGlobalStatus === 'success'

  return (
    <Stack gap={2} width="100%">
      <Button
        onClick={startPaypal}
        fullWidth
        size="large"
        variant="contained"
        isLoading={isPending || isSuccess}
        loadingMessage={t('processing')}
        className={classes.paypalButton}
        disabled={isDisabled}
      >
        <PaypalIcon width={63} height={20} className={classes.paypalIcon} />
      </Button>
      {error && <Alert severity="error">{error}</Alert>}
    </Stack>
  )
}
