'use client'

import { Alert, Skeleton, Stack, Typography } from '@mui/material'
import { CardElement, type CardElementChangeEvent, ThreeDSecureAction } from '@recurly/react-recurly'
import { useAtomValue } from 'jotai'
import { useTranslations } from 'next-intl'
import { useRef, useState } from 'react'

import { useAnalytics } from '@/modules/analytics/hooks/use-analytics'
import { trpc } from '@/modules/api/trpc/lib/browser'
import { Button } from '@/modules/ui/components/button'
import { pxToRem } from '@/modules/utils/lib/conversions'

import classes from './card-payment.module.scss'
import { checkoutMutationGlobalStatusAtom, type Plan } from '../../atoms'
import { useCreditCardPayment } from '../../hooks/use-credit-card-payment'

type CardPaymentProps = {
  readonly plan: Plan
  readonly submitLabel?: string
  readonly successRedirect?: () => void
}

export function CardPayment({ plan, submitLabel, successRedirect }: CardPaymentProps) {
  const checkoutMutationGlobalStatus = useAtomValue(checkoutMutationGlobalStatusAtom)
  const t = useTranslations()
  const { data: user, isPending: isUserPending } = trpc.users.me.useQuery()

  const formRef = useRef<HTMLFormElement>(null)
  const {
    submitPayment,
    errorMessage,
    setRecurlyError,
    token3DS,
    submit3DS,
    isPending: isPaymentPending,
  } = useCreditCardPayment(formRef, plan, { successRedirect })
  const [zip, setZip] = useState('')
  const [cardEvent, setCardEvent] = useState<CardElementChangeEvent | null>(null)

  const { logEvent } = useAnalytics()

  function handleZipChange(event: React.ChangeEvent<HTMLInputElement>) {
    setZip(event.target.value)
  }

  if (isUserPending) {
    return (
      <Stack direction="column" alignItems="center">
        <Skeleton width="100%" height={32} />
        <Skeleton width="100%" height={42} />
        <Skeleton width="100%" height={42} />
      </Stack>
    )
  }

  const isDisabled =
    !cardEvent ||
    !cardEvent.valid ||
    !zip ||
    checkoutMutationGlobalStatus === 'success' ||
    checkoutMutationGlobalStatus === 'pending'

  return (
    <form ref={formRef} onSubmit={submitPayment} className={classes.form}>
      {token3DS && (
        <ThreeDSecureAction
          onToken={({ id }) => submit3DS(id)}
          actionTokenId={token3DS}
          onError={(error) => setRecurlyError(error.message)}
        />
      )}
      <Stack gap={2} p={0}>
        <Typography>{t('checkout.creditOrDebit')}</Typography>
        <input type="hidden" data-recurly="first_name" value={user?.lastName} />
        <input type="hidden" data-recurly="last_name" value={user?.firstName} />

        <Stack direction="row" gap={2} alignItems="center" mb={2}>
          <CardElement
            onChange={setCardEvent}
            className={classes.cardElement}
            style={{
              fontSize: pxToRem(14) as string,
            }}
          />
          <input
            onChange={handleZipChange}
            type="text"
            data-recurly="postal_code"
            placeholder="ZIP"
            className={classes.zip}
          />
        </Stack>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <Button
          size="large"
          variant="contained"
          type="submit"
          loadingMessage={t('common.processing')}
          onClick={() => logEvent('getverified')}
          isLoading={isPaymentPending}
          disabled={isDisabled}
        >
          {submitLabel ?? t('checkout.submitPayment')}
        </Button>
      </Stack>
    </form>
  )
}
