/* eslint-disable no-restricted-syntax */
'use client'

import { type ButtonProps as MuiButtonProps, Button as MuiButton } from '@mui/material'
import clsx from 'clsx'

import { useAnalytics } from '@/modules/analytics/hooks/use-analytics'
import { type AnalyticsOptions } from '@/modules/analytics/lib/const'

import classes from './button.module.scss'
import { SpinnerIcon } from '../../icons/spinner'

export type ButtonProps = MuiButtonProps & {
  readonly isLoading?: boolean
  readonly loadingMessage?: string
  readonly analytics?: AnalyticsOptions | AnalyticsOptions[]
}

export function Button({
  isLoading,
  children,
  disabled,
  loadingMessage,
  endIcon,
  className,
  variant = 'text',
  analytics,
  onClick,
  ...otherProps
}: ButtonProps) {
  const { logEvent } = useAnalytics()

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (analytics) {
      if (Array.isArray(analytics)) {
        for (const analytic of analytics) {
          logEvent(analytic.eventName, analytic.eventProperties)
        }
      } else {
        logEvent(analytics.eventName, analytics.eventProperties)
      }
    }

    onClick?.(event)
  }

  return (
    <MuiButton
      {...otherProps}
      onClick={handleClick}
      variant={variant}
      disabled={isLoading || disabled}
      endIcon={isLoading ? <SpinnerIcon width={20} height={20} /> : endIcon}
      className={clsx(className, classes[variant], isLoading && classes.loading)}
    >
      {isLoading ? (loadingMessage ?? children) : children}
    </MuiButton>
  )
}
