const PX_TO_REM_RATIO = 16

export function pxToRem(pixels: number, includeUnit = true) {
  const remValue = pixels / PX_TO_REM_RATIO

  return includeUnit ? `${remValue}rem` : remValue
}

export function pxToEm(pixels: number, includeUnit = true) {
  const remValue = pixels / PX_TO_REM_RATIO

  return includeUnit ? `${remValue}em` : remValue
}
