'use client'

import { atom, useAtomValue, useSetAtom } from 'jotai'
import React from 'react'

import { env } from '@/config/env'
import { trpc } from '@/modules/api/trpc/lib/browser'

type BrazeAtom = {
  changeUser: (userId: string) => void
  openSession: () => void
}

const brazeAtom = atom<Nullable<BrazeAtom>>(null)

export function BrazeInit() {
  const setBraze = useSetAtom(brazeAtom)
  const { mutateAsync: createBrazeSignature } = trpc.createBrazeSignatureToken.useMutation()

  React.useEffect(() => {
    /**
     * @see https://www.braze.com/docs/developer_guide/platform_integration_guides/web/initial_sdk_setup/#ssr
     */
    void import('../lib/braze-exports').then(
      ({ initialize, automaticallyShowInAppMessages, openSession, changeUser }) => {
        initialize(env('NEXT_PUBLIC_BRAZE_SDK_KEY'), {
          baseUrl: env('NEXT_PUBLIC_BRAZE_ENDPOINT'),
          enableSdkAuthentication: true,
          doNotLoadFontAwesome: true,
        })
        automaticallyShowInAppMessages()
        openSession()

        async function handleChangeUser(userId: string) {
          const signature = await createBrazeSignature({ userId })

          /**
           * Since we are using the `enableSdkAuthentication` we need to pass the signature
           * @see https://www.braze.com/docs/developer_guide/platform_wide/sdk_authentication/#generate-keys
           */
          changeUser(userId, signature ?? undefined)
        }

        setBraze({
          changeUser: handleChangeUser,
          openSession,
        })
      }
    )
  }, [setBraze, createBrazeSignature])

  return null
}

export function useBraze() {
  return useAtomValue(brazeAtom)
}
