'use client'

import { Elements, RecurlyProvider } from '@recurly/react-recurly'
import Script from 'next/script'
import { useState, type PropsWithChildren } from 'react'

import { env } from '@/config/env'

export function RecurlyAppProvider({ children }: PropsWithChildren) {
  const [isReady, setIsReady] = useState(false)

  return (
    <>
      {/* It is not recommended to self-host the Recurly.js script. See https://recurly.com/developers/reference/recurly-js/#how-it-works.
      The script must be ready before the RecurlyProvider is mounted. (Did not find other way to make it work) */}
      <Script src="https://js.recurly.com/v4/recurly.js" onReady={() => setIsReady(true)} />
      {isReady && (
        <RecurlyProvider publicKey={env('NEXT_PUBLIC_RECURLY_PUBLIC_KEY')}>
          <Elements>{children}</Elements>
        </RecurlyProvider>
      )}
    </>
  )
}
