'use strict'

// JavaScript file because it's shared with build processes.

/**
 * Environment variables.
 *
 * Never make this dynamic: Next.js needs `process.env.*` syntax to build envs statically.
 */
const variables = {
  NODE_ENV: process.env.NODE_ENV,

  SECRET_CIPHER_KEY: process.env.SECRET_CIPHER_KEY,
  BRAZE_RSA: process.env.BRAZE_RSA,

  HAMMER_TIME_API_ORIGIN: process.env.HAMMER_TIME_API_ORIGIN,
  HAMMER_TIME_API_PATH: process.env.HAMMER_TIME_API_PATH,
  HAMMER_TIME_API_SIGNATURE_KEY: process.env.HAMMER_TIME_API_SIGNATURE_KEY,
  ROLLBAR_SERVER_TOKEN: process.env.ROLLBAR_SERVER_TOKEN,

  /**
   * Exposed to the client-side
   */
  NEXT_PUBLIC_BASE_WEB_URL: process.env.NEXT_PUBLIC_BASE_WEB_URL,
  NEXT_PUBLIC_FACEBOOK_APP_ID: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
  NEXT_PUBLIC_RECAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,

  NEXT_PUBLIC_HOTJAR_ID: process.env.NEXT_PUBLIC_HOTJAR_ID,
  NEXT_PUBLIC_AMPLITUDE_API_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  NEXT_PUBLIC_BRAZE_SDK_KEY: process.env.NEXT_PUBLIC_BRAZE_SDK_KEY,
  NEXT_PUBLIC_BRAZE_ENDPOINT: process.env.NEXT_PUBLIC_BRAZE_ENDPOINT,
  NEXT_PUBLIC_ONE_TRUST_DOMAIN_ID: process.env.NEXT_PUBLIC_ONE_TRUST_DOMAIN_ID,
  NEXT_PUBLIC_RECURLY_PUBLIC_KEY: process.env.NEXT_PUBLIC_RECURLY_PUBLIC_KEY,
  NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
  NEXT_PUBLIC_COMMIT_HASH: process.env.NEXT_PUBLIC_COMMIT_HASH,
}

/**
 * Type-safe constrain to enforce an environment variable to be
 * available and return it's value, or throw otherwise.
 *
 * @type {<K extends keyof typeof variables, B extends boolean | undefined = undefined>(name: K, enforce?: B) => B extends false ? (string | undefined) : string}
 */
const env = (name, enforce) => {
  if (enforce !== false && typeof variables[name] === 'undefined') {
    throw new Error(`Missing ${name} environment variable`)
  }

  return variables[name]
}

module.exports = { variables, env }
