'use client'

import { init, track, setUserId, reset, logEvent } from '@amplitude/analytics-browser'
import { atom, useAtomValue } from 'jotai'
import React from 'react'

import { env } from '@/config/env'

export type AmplitudeAtom = {
  track: typeof track
  setUserId: typeof setUserId
  reset: typeof reset
  logEvent: typeof logEvent
}

export const amplitudeAtom = atom<AmplitudeAtom>({
  track,
  setUserId,
  reset,
  logEvent,
})

export function AmplitudeInit({ children }: React.PropsWithChildren) {
  React.useEffect(() => {
    init(env('NEXT_PUBLIC_AMPLITUDE_API_KEY'), {
      autocapture: true,
    })
  }, [])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export function useAmplitude() {
  return useAtomValue(amplitudeAtom)
}
