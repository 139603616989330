import { env } from './env'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const IS_DEVELOPMENT = env('NODE_ENV') === 'development'

export function getEnvironment() {
  if (IS_DEVELOPMENT) {
    return 'development'
  }

  try {
    const url = new URL(env('NEXT_PUBLIC_BASE_WEB_URL'))

    switch (url.host) {
      case 'couchsurfing.com':
      case 'www.couchsurfing.com':
        return 'production'
      case 'www.staging.couchsurfing.com':
      case 'staging.couchsurfing.com':
        return 'staging'
      case 'localhost:3000':
      case 'site.dev.cs:3000':
      default:
        return 'development'
    }
  } catch {
    return 'development'
  }
}
