import React from 'react'

import { IS_DEVELOPMENT } from '@/config'

import { useAmplitude } from '../config/amplitude'
import { useBraze } from '../config/braze'
import { type ANALYTICS_EVENT } from '../lib/const'

export function useAnalytics() {
  const amplitude = useAmplitude()
  const braze = useBraze()

  const identifyUser = React.useCallback(
    (userId: string) => {
      amplitude.setUserId(userId)

      braze?.changeUser(userId)
      braze?.openSession()
    },
    [amplitude, braze]
  )

  const reset = React.useCallback(() => {
    amplitude.reset()
    // No need to reset Braze (@see https://www.braze.com/docs/developer_guide/platform_integration_guides/web/analytics/setting_user_ids)
  }, [amplitude])

  const logEvent = React.useCallback(
    (eventName: ANALYTICS_EVENT, eventProperties?: Record<string, unknown>) => {
      if (IS_DEVELOPMENT) {
        console.log(eventName, eventProperties)
      }
      amplitude.logEvent(eventName, eventProperties)
    },
    [amplitude]
  )

  return {
    identifyUser,
    reset,
    logEvent,
  }
}
