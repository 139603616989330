import { useSetAtom } from 'jotai'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect } from 'react'

import { ROUTES } from '@/config/routes'
import { useAnalytics } from '@/modules/analytics/hooks/use-analytics'
import { isHammerTimeError } from '@/modules/api/hammer-time/lib/errors'
import { trpc } from '@/modules/api/trpc/lib/browser'

import { checkoutMutationGlobalStatusAtom } from '../atoms'

type UseCheckoutMutationOptions = Parameters<typeof trpc.commerce.checkout.useMutation>[0] & {
  readonly successRedirect?: () => void
}

export function useCheckoutMutation(mutationOptions?: UseCheckoutMutationOptions) {
  const setCheckoutMutationGlobalStatus = useSetAtom(checkoutMutationGlobalStatusAtom)
  const router = useRouter()
  const utils = trpc.useUtils()
  const { logEvent } = useAnalytics()
  const pathname = usePathname()

  const mutation = trpc.commerce.checkout.useMutation({
    ...mutationOptions,
    onError: (...args) => {
      const err = args[0]
      const payload = args[1]

      logEvent('membership_process_payment_token_failed', {
        pathname,
        payment_provider_type: 'recurly',
        membership_product_id: payload.psp_product_id,
        reason: isHammerTimeError(err.data) ? err.data.hammertimeError?.map((item) => item.code) : 'unknown_error',
        api_error_code: err.data?.httpStatus,
        api_error_message: err.message,
      })

      setCheckoutMutationGlobalStatus('error')
      mutationOptions?.onError?.(...args)
    },
    onMutate: (...args) => {
      const payload = args[0]

      logEvent('membership_process_payment_token', {
        pathname,
        payment_provider_type: 'recurly',
        membership_product_id: payload.psp_product_id,
      })

      setCheckoutMutationGlobalStatus('pending')
      mutationOptions?.onMutate?.(...args)
    },
    onSuccess: async (...args) => {
      setCheckoutMutationGlobalStatus('success')
      await utils.commerce.products.invalidate()

      if (mutationOptions?.successRedirect) {
        return mutationOptions.successRedirect()
      }

      router.push(ROUTES.PROTECTED.CONFIRMATION)

      mutationOptions?.onSuccess?.(...args)
    },
  })

  useEffect(() => {
    setCheckoutMutationGlobalStatus('idle')
  }, [setCheckoutMutationGlobalStatus])

  return mutation
}
