export const WEB_VIEW_PREFIX = `/app/mobile-webviews`

export const ROUTES = {
  /**
   * PUBLIC
   */
  HOME: '/welcome',
  SIGN_IN: '/welcome/sign-in',
  CREATE_ACCOUNT: '/auth/create-account',

  /**
   * PROTECTED
   */
  PROTECTED: {
    PLAYGROUND: '/app/playground',
    MEMBERSHIP: '/app/membership',
    CHECKOUT: '/app/checkout',
    LOGOUT: '/app/logout',
    CONFIRMATION: '/app/confirmation',
    VERIFICATION: '/app/get-verified',
    IOS_WEB_VIEW_VERIFICATION: `${WEB_VIEW_PREFIX}/ios/get-verified`,
    ANDROID_WEB_VIEW_VERIFICATION: `${WEB_VIEW_PREFIX}/android/get-verified`,
  },

  /**
   * API
   */
  TRPC: '/api/signup/trpc',
  HEALTH_CHECK: '/api/signup/health-check',
} as const
