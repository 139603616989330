import { type DefaultErrorShape } from '@trpc/server/unstable-core-do-not-import'

import { flatStringValuesFromObject } from '@/modules/utils/lib/string-values-from-object'

export const HAMMERTIME_API_ERRORS = {
  AUTH: {
    MISSING_FIELD: 'missing_field',
  },
  SESSION: {
    INVALID_ACTION_TYPE: 'invalid_action_type',
    BAD_CREDENTIALS: 'bad_credentials',
    ACCOUNT_LOCKED: 'account_locked',
    USER_AGREEMENT_NEEDED: 'user_agreement_needed',
    USER_DEACTIVATED: 'user_deactivated',
    INVALID_SIGNATURE: 'invalid_signature',
    INVALID_TOKEN: 'invalid_token',
  },
  FACEBOOK: {
    NO_EMAIL: 'facebook_no_email',
    NO_BIRTHDAY: 'facebook_no_birthday',
    NO_GENDER: 'facebook_no_gender',
    NO_LOCATION: 'facebook_no_location',
  },
} as const

export const HAMMERTIME_API_ERROR_CODES = flatStringValuesFromObject(HAMMERTIME_API_ERRORS)

export class HammerTimeError extends Error {
  errors: ReadonlyArray<{ readonly code: string; can_reactivate?: boolean }>
  status: number

  constructor(
    message: string,
    status: number,
    errors: ReadonlyArray<{ readonly code: string; can_reactivate?: boolean }>
  ) {
    super(message)
    this.name = 'HammerTimeError'
    this.errors = errors
    this.status = status
  }
}

export class MissingExpectedDataError extends HammerTimeError {
  constructor(endpoint: string) {
    super('Missing expected data', 500, [])
    this.name = `MissingExpectedDataError on ${endpoint}`
  }
}

export function isHammerTimeError(
  errorData: Maybe<DefaultErrorShape['data']>
): errorData is DefaultErrorShape['data'] & { hammertimeError?: HammerTimeError['errors'] } {
  return Boolean(errorData && 'hammertimeError' in errorData)
}
